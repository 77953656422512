import { Button, Alert } from "react-bootstrap";
import { useEffect, useState } from "react";
import "../assets/css/login.css";
import { authService } from "../services/auth.service";
import Loading from "../components/Util/Loading";
function Login(props) {
  const [state, setState] = useState({
    username: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [showMessage, setShowMessage] = useState(false);

  let messageBox = null;
  //   useEffect(() => {

  //     if (showMessage) {
  //       messageBox = (
  //         <Alert variant="danger" dismissible>
  //           <button
  //             aria-hidden={true}
  //             className="close"
  //             data-dismiss="alert"
  //             type="button"
  //             onClick={() => {
  //               setShowMessage(false);
  //             }}
  //           >
  //             <i className="nc-icon nc-simple-remove"></i>
  //           </button>
  //           <span>{message}</span>
  //         </Alert>
  //       );
  //     }
  //   });

  if (showMessage) {
    messageBox = (
      <Alert variant="danger" dismissible>
        <button
          aria-hidden={true}
          className="close"
          data-dismiss="alert"
          type="button"
          onClick={() => {
            setShowMessage(false);
          }}
        >
          <i className="nc-icon nc-simple-remove"></i>
        </button>
        <span>{message}</span>
      </Alert>
    );
  }

  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  return (
    <div className="login-wrapper">
      <div id="formContent">
        <div>
          <img
            src="https://www.upoint.mn/images/black-logo.png"
            id="icon"
            alt="User Icon"
          />
        </div>
        <form
          onSubmit={async (e) => {
            let res;
            try {
              e.preventDefault();
              setLoading(true);
              res = await authService.login(state.username, state.password);
              setShowMessage(true);
              if (res.message === "Network Error") {
                setMessage("Сүлжээний холболтоо шалгана уу!");
              } else if (res.error == "UserNotFound") {
                setMessage("Хэрэглэгч олдсонгүй.");
              } else if (res.error == "UserDeactivated") {
                setMessage("Хэрэглэгч идэвхгүй төлөвт шилжсэн байна.");
              } else if (res.error == "InvalidPassword") {
                setMessage("Нууц үгээ шалгаад дахин оруулна уу!");
              } else {
                setMessage("Нэвтрэх нэр, нууц үгээ шалгаад дахин оруулна уу!");
              }
              setLoading(false);
            } catch (err) {
              console.log(err);
            }
          }}
        >
          <input
            type="text"
            id="username"
            className="login-input"
            name="Хэрэглэгчийн нэр"
            placeholder="Хэрэглэгчийн нэр"
            required
            disabled={loading}
            value={state.username}
            onChange={handleChange}
          />
          <input
            type="password"
            id="password"
            className="login-input"
            name="Нууц үг"
            placeholder="Нууц үг"
            required
            value={state.password}
            disabled={loading}
            onChange={handleChange}
          />
          <Button
            className="login-input"
            value="Log In"
            type="submit"
            disabled={loading}
          >
            НЭВТРЭХ
          </Button>
        </form>
        <div id="formFooter">
          <Loading loading={loading} />
          {messageBox}
        </div>
      </div>
    </div>
  );
}
export default Login;
