import {
  Button,
  Card,
  Table,
  Form,
  Navbar,
  Container,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import CollectiveInfo from "../components/Collective/CollectiveInfo";
import CollectiveMembers from "../components/Collective/CollectiveMembers";
import UntMembers from "../components/Collective/UntMembers";
import ConsumerCards from "../components/Consumer/ConsumerCards";
import ConsumerService from "../services/consumer.service";
import LogService from "../services/consumer_log.service";
import { formatPretty } from "../helpers/dateHelper";
import { useState, useEffect } from "react";
import GenericModal from "../components/Modal/GenericModal";
import consumerService from "../services/consumer.service";
import Loading from "components/Util/Loading";
import Univision from "./Univision";
function UserInfo(props) {
  const [stateValue, setStateValue] = useState();
  const [state, Setstate] = useState(null);
  const [checkLoading, setCheckLoading] = useState(false);
  const [migrationMobile, setMigrationMobile] = useState();
  const [migrationStatus, setMigrationStatus] = useState(false);
  const checkMobile = async () => {
    try {
      setCheckLoading(true);
      const response = await consumerService.checkActive(stateValue.trim());
      Setstate(response.data);
    } catch (err) {
      console.log(err);
      Setstate(null);
    } finally {
      setCheckLoading(false);
    }
  };
  const checkMigration = async () => {
    try {
      setMigrationStatus(true);
      const response = await consumerService.checkMigration(
        migrationMobile.trim()
      );
      props.notify("tr", "success", `Амжилттай.`);
    } catch (err) {
      console.log(err);
    } finally {
      setMigrationStatus(false);
    }
  };
  const [messageBody, setMessageBody] = useState("");
  const [notiBody, setNotiBody] = useState("");
  const [pinCode, setPinCode] = useState(false);
  const ConsumerUnBlock = async ({ mobile }) => {
    try {
      if (props.consumer.mobile) {
        const response = await ConsumerService.unBlockTAN(
          props.consumer.mobile
        );
        props.notify(
          "tr",
          "success",
          `${props.consumer.mobile} дугаартай хэрэглэгчийн блокыг гаргалаа.`
        );
      }
    } catch (err) {
      console.log("catch error", err);
      props.notify("tr", "danger", err.message);
    }
  };
  const ConsumerUnBlockPin = async ({ mobile }) => {
    try {
      if (props.consumer.mobile) {
        const response = await ConsumerService.unBlockPin(
          props.consumer.mobile
        );
        props.notify(
          "tr",
          "success",
          `${props.consumer.mobile} дугаартай хэрэглэгчийн пин блокыг гаргалаа`
        );
      }
    } catch (err) {
      console.log("catch error", err);
      props.notify("tr", "danger", err.message);
    }
  };

  const sendPin = async () => {
    try {
      // if (
      //   props.currentUser.role === "admin" ||
      //   props.currentUser.role === "crm_specialist"
      // ) {
      let result = await ConsumerService.sendPin(props.consumer._id);
      props.notify(
        "tr",
        "success",
        `${result.message || "Пин код илгээгдлээ"}`
      );
      await props.refreshCollective();
      // } else {
      //   props.notify(
      //     "tr",
      //     "danger",
      //     "админ эрхтэй ажилтан пин код илгээх эрхтэй"
      //   );
      // }
    } catch (err) {
      console.log(err);
      props.notify("tr", "danger", err.message);
    }
  };
  const getPin = async () => {
    try {
      let result = await ConsumerService.getPin(
        props.consumer?.profile?.registration_number || props.consumer?.mobile
      );
      props.notify(
        "tr",
        "success",
        `${result.message || "Пин код илгээгдлээ"}`
      );
    } catch (err) {
      console.log(err);
      props.notify("tr", "danger", err.message);
    }
  };

  const sendSMS = async () => {
    try {
      await LogService.sendSMS(props.consumer?.mobile, messageBody);
      setMessageBody("");
      props.notify("tr", "success", `Мессеж илгээгдлээ`);
    } catch (err) {
      props.notify("tr", "danger", err.message);
    }
  };
  const sendNoti = async () => {
    try {
      await LogService.sendNoti(props.consumer?._id, notiBody);
      setNotiBody("");
      props.notify("tr", "success", `Ноти илгээгдлээ`);
    } catch (err) {
      props.notify("tr", "danger", err.message);
    }
  };
  useEffect(() => {
    // setMessageBody("");
    let mobNumPre = Math.floor(props.consumer?.mobile / 10000000);
    if (mobNumPre === 8 || mobNumPre === 9) {
      setPinCode(false);
    } else {
      setPinCode(true);
    }
  });

  if (!props.consumer || !props.consumer.profile) {
    return (
      <Container fluid>
        <h4 style={{ marginLeft: 20 }}>
          Хэрэглэгчийн мэдээлэл байхгүй байна. Хэрэглэгч хайна уу!
        </h4>
        <Row>
          <Col md="9" lg="8">
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center mb-3">
                <Card.Title as="h4" className="mb-0">
                  Төлөв шалгах
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col sm="3">
                      <Form>
                        <Form.Row className="align-items-center">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Дугаар</Form.Label>
                            <Form.Control
                              type="text"
                              value={stateValue}
                              onChange={(e) => {
                                setStateValue(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm="3"
                            controlId="formGridPassword"
                          >
                            <Button
                              style={{ marginTop: "20px" }}
                              variant="primary"
                              className="btn btn-sm"
                              size="md"
                              onClick={checkMobile}
                            >
                              Шалгах
                            </Button>
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    </Col>
                    <Col sm="9">
                      <Table
                        className="table-hover"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="border-0">Төлөв</th>
                          </tr>
                        </thead>
                        {state === null ? null : (
                          <tbody style={{ wordBreak: "break-all" }}>
                            <tr>
                              <td>{state.message}</td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                      <Loading loading={checkLoading} />
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>

          <Col md="3" lg="3">
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center mb-3">
                <Card.Title as="h4" className="mb-0">
                  Migration хийх
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col sm="12">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        checkMigration();
                      }}
                    >
                      <Form.Row className="align-items-center">
                        <Form.Group as={Col} controlId="formGridEmail">
                          <Form.Label>Дугаар</Form.Label>
                          <Form.Control
                            type="text"
                            value={migrationMobile}
                            onChange={(e) => {
                              setMigrationMobile(e.target.value);
                            }}
                          />
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          sm="3"
                          controlId="formGridPassword"
                        >
                          {migrationStatus ? (
                            <>
                              <Loading loading={migrationStatus} />
                            </>
                          ) : (
                            <>
                              <Button
                                style={{ marginTop: "20px" }}
                                variant="primary"
                                className="btn btn-sm"
                                size="md"
                                onClick={checkMigration}
                              >
                                Шалгах
                              </Button>
                            </>
                          )}
                        </Form.Group>
                      </Form.Row>
                    </Form>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col md="12" lg="8">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center mb-3">
              <Card.Title as="h4" className="mb-0">
                Хэрэглэгчийн мэдээлэл
              </Card.Title>
              <Button
                className="btn btn-sm"
                variant="primary"
                onClick={() => {
                  props.showAlert(
                    "success",
                    "Хэрэглэгчийн мэдээллийг амжилттай шинэчиллээ"
                  );
                }}
              >
                <i className="fa fa-edit"></i>
                Мэдээлэл шинэчлэх
              </Button>
            </Card.Header>

            <Card.Body>
              <Form>
                <Row>
                  <Col className="pl-1" md="3">
                    <Form.Group>
                      <label>Овог</label>
                      <Form.Control
                        value={props.consumer.profile.last_name || ""}
                        placeholder="Овог"
                        type="text"
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="3">
                    <Form.Group>
                      <label>Нэр</label>
                      <Form.Control
                        value={props.consumer.profile.first_name || ""}
                        placeholder="Нэр"
                        type="text"
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="3">
                    <Form.Group>
                      <label htmlFor="exampleInputEmail1">Регистер</label>
                      <Form.Control
                        placeholder="Регистрийн дугаар"
                        value={props.consumer.profile.registration_number || ""}
                        type="text"
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Col>

                  <Col className="pl-1" md="3">
                    <Form.Group>
                      <label htmlFor="exampleInputEmail1">Утасны дугаар</label>
                      <Form.Control
                        placeholder="Утасны дугаар"
                        value={props.consumer.mobile}
                        type="text"
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="pl-1" md="9">
                    <Form.Group>
                      <label>Гэрийн хаяг</label>
                      <Form.Control
                        value={props.consumer.profile.address || ""}
                        placeholder="Гэрийн хаяг"
                        type="text"
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="3">
                    <Form.Group>
                      <label>Пин код</label>
                      <Form.Control
                        value={pinCode ? props.consumer.pin_code || "" : ""}
                        placeholder=""
                        type="text"
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="pl-1" md="2">
                    <Form.Group>
                      <label>Бүртгүүлсэн огноо</label>
                      <Form.Control
                        value={formatPretty(props.consumer.created_at)}
                        placeholder="Хэрэглэгч болсон огноо"
                        type="text"
                        plaintext
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="2">
                    <Form.Group>
                      <label>Дансны үлдэгдэл</label>
                      <Form.Control
                        value={props.consumer.balance || 0}
                        placeholder="Гэрийн хаяг"
                        type="text"
                        plaintext
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="2">
                    <Form.Group>
                      <label>Дансны төлөв</label>
                      <Form.Control
                        value={
                          props.consumer.balance_status === 0 ? "Түр" : "үндсэн"
                        }
                        placeholder="Гэрийн хаяг"
                        type="text"
                        plaintext
                        disabled
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="2">
                    <Form.Group>
                      <label>Апп-д бүртгэлтэй эсэх</label>
                      <Form.Control
                        value={
                          props.consumer?.devices?.length === 0
                            ? "Үгүй"
                            : "Тийм"
                        }
                        type="text"
                        plaintext
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col className="pl-1" md="2">
                    <div>
                      <GenericModal
                        onClick={ConsumerUnBlock}
                        params={{}}
                        action={"Тан блок гаргах"}
                        title={"Тан блок гаргах"}
                        body={`Та ${props.consumer.mobile} дугаартай хэрэглэгчийн блок гаргах уу.`}
                        type={"button"}
                      />
                    </div>
                  </Col>
                  <Col className="pl-1" md="2">
                    <div>
                      <GenericModal
                        onClick={ConsumerUnBlockPin}
                        params={{}}
                        action={"Пин блок гаргах"}
                        title={"Пин блок гаргах"}
                        body={`Та ${props.consumer.mobile} дугаартай хэрэглэгчийн блок гаргах уу.`}
                        type={"button"}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
              <ConsumerCards consumer={props.consumer} />
              <hr />
              <Row
                style={{ width: "100%", height: "100%", marginBottom: "20px" }}
              >
                <Col md="3">
                  {props.currentUser.role === "admin" || "crm_specialist" ? (
                    <div>
                      <GenericModal
                        onClick={sendPin}
                        params={{}}
                        action={"Пин код илгээх"}
                        title={"Пин код илгээх"}
                        body={`Та ${props.consumer.mobile} дугаартай хэрэглэгч рүү пинкод илгээхдээ итгэлтэй байна уу.`}
                        type={"button"}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col md="2">
                  <div>
                    <GenericModal
                      onClick={getPin}
                      params={{}}
                      action={"Пин код авах"}
                      title={"Пин код авах"}
                      body={`Та ${props.consumer.mobile} дугаартай хэрэглэгчийн пинкодыг авахдаа итгэлтэй байна уу.`}
                      type={"button"}
                    />
                  </div>
                </Col>
              </Row>
              <Row style={{ width: "100%", height: "100%" }}>
                <Col className="pl-1">
                  <Form.Label htmlFor="inlineFormInputGroup" srOnly>
                    Username
                  </Form.Label>
                  <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{props.consumer.mobile}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      value={messageBody}
                      onChange={(e) => {
                        setMessageBody(e.target.value);
                      }}
                      placeholder="Мессеж текст"
                      type="text"
                    />
                  </InputGroup>
                </Col>

                <Col md="1" style={{ height: "100%" }} className="align-middle">
                  <GenericModal
                    onClick={sendSMS}
                    params={{}}
                    action={"Илгээх"}
                    title={"Мессеж илгээх"}
                    body={`Та ${props.consumer.mobile} дугаартай хэрэглэгч рүү мессеж илгээхдээ итгэлтэй байна уу?`}
                    type={"button"}
                  />
                </Col>
              </Row>
              <Row style={{ width: "100%", height: "100%" }}>
                <Col className="pl-1">
                  <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                      <InputGroup.Text>{props.consumer.mobile}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      value={notiBody}
                      onChange={(e) => {
                        setNotiBody(e.target.value);
                      }}
                      placeholder="Ноти текст"
                      type="text"
                    />
                  </InputGroup>
                </Col>

                <Col md="1" style={{ height: "100%" }} className="align-middle">
                  <GenericModal
                    onClick={sendNoti}
                    params={{}}
                    action={"Илгээх"}
                    title={"Ноти илгээх"}
                    body={`Та ${props.consumer.mobile} дугаартай хэрэглэгч рүү ноти илгээхдээ итгэлтэй байна уу?`}
                    type={"button"}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md="6" lg="4">
          <CollectiveInfo
            collective={props.collective}
            showAlert={props.showAlert}
            notify={props.notify}
          />
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center mb-3">
              <Card.Title as="h4" className="mb-0">
                Төлөв шалгах
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      checkMobile();
                    }}
                  >
                    <Form.Row className="align-items-center">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Дугаар</Form.Label>
                        <Form.Control
                          type="text"
                          value={stateValue}
                          onChange={(e) => {
                            setStateValue(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm="3" controlId="formGridPassword">
                        <Button
                          style={{ marginTop: "20px" }}
                          variant="primary"
                          className="btn btn-sm"
                          size="md"
                          onClick={checkMobile}
                        >
                          Шалгах
                        </Button>
                      </Form.Group>
                    </Form.Row>
                  </Form>
                </Col>
                <Col sm="9">
                  <Table
                    className="table-hover"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th className="border-0">Төлөв</th>
                      </tr>
                    </thead>
                    {state === null ? null : (
                      <tbody style={{ wordBreak: "break-all" }}>
                        <tr>
                          <td>{state.message}</td>
                        </tr>
                      </tbody>
                    )}
                  </Table>
                  <Loading loading={checkLoading} />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col md="12" lg="8">
          <CollectiveMembers
            collective={props.collective}
            showAlert={props.showAlert}
            notify={props.notify}
            refreshCollective={props.refreshCollective}
          ></CollectiveMembers>
        </Col>
        <Col md="6" lg="4">
          <UntMembers
            collective={props.collective}
            refreshCollective={props.refreshCollective}
          ></UntMembers>
        </Col>
      </Row>

      <Row>
        <Col md="12" lg="8">
          <Card>
            <Card.Header>
              <Card.Title>Wallets</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table
                className="table-hover table-striped"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th className="border-0">Дугаар</th>
                    <th className="border-0">Үүссэн огноо</th>
                    <th className="border-0">Партнер нэр</th>
                    <th className="border-0">Үлдэгдэл</th>
                  </tr>
                </thead>
                <tbody>
                  {props.wallets?.map((wallet) => (
                    <tr key={wallet._id}>
                      <td>{wallet.account_no}</td>
                      <td>{formatPretty(wallet.created_at)}</td>
                      <td>{wallet.partner_name}</td>
                      <td>{wallet.balance}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col md="3" lg="3">
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center mb-3">
              <Card.Title as="h4" className="mb-0">
                Migration хийх
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col sm="12">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                      checkMigration();
                    }}
                  >
                    <Form.Row className="align-items-center">
                      <Form.Group as={Col} controlId="formGridEmail">
                        <Form.Label>Дугаар</Form.Label>
                        <Form.Control
                          type="text"
                          value={migrationMobile}
                          onChange={(e) => {
                            setMigrationMobile(e.target.value);
                          }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} sm="3" controlId="formGridPassword">
                        {migrationStatus ? (
                          <>
                            <Loading loading={migrationStatus} />
                          </>
                        ) : (
                          <>
                            <Button
                              style={{ marginTop: "20px" }}
                              variant="primary"
                              className="btn btn-sm"
                              size="md"
                              onClick={checkMigration}
                            >
                              Шалгах
                            </Button>
                          </>
                        )}
                      </Form.Group>
                    </Form.Row>
                  </Form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Univision
        consumer_id={props.consumer._id}
        mobile={props.consumer.mobile}
        notify={props.notify}
      />
      <Row>
        <Col md="12" lg="8"></Col>
      </Row>
    </Container>
  );
}

export default UserInfo;
