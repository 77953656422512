import { Card, Table, Button } from "react-bootstrap";
import HamtdaaCheckModal from "../Modal/HamtdaaCheckModal";
import { useState, useEffect } from "react";

function UntMembers(props) {
  useEffect(() => {});
  if (
    !props.collective ||
    !props.collective.unt_members ||
    props.collective.unt_members.length === 0
  ) {
    return (
      <Card>
        <Card.Header className="d-flex justify-content-between align-items-center mb-3">
          <Card.Title as="h4" className="mb-0">
            Хамтдаа Гишүүд
          </Card.Title>
          {props.collective ? (
            <HamtdaaCheckModal
              familyId={props.collective.family_id}
              refreshCollective={props.refreshCollective}
            />
          ) : (
            <div></div>
          )}
        </Card.Header>
        <Card.Body>Одоогоор Хамтдаад бүртгэлгүй байна</Card.Body>
      </Card>
    );
  }

  return (
    <Card>
      <Card.Header className="d-flex justify-content-between align-items-center mb-3">
        <Card.Title as="h4" className="mb-0">
          Хамтдаа Гишүүд
        </Card.Title>
        <HamtdaaCheckModal
          familyId={props.collective.family_id}
          refreshCollective={props.refreshCollective}
        />
      </Card.Header>
      <Card.Body>
        <Table
          className="table-hover table-striped"
          style={{
            fontSize: "12px",
          }}
        >
          <thead>
            <tr>
              <th className="border-0">Утасны дугаар</th>
              <th className="border-0">Group Id</th>
              <th className="border-0">Member Type</th>
              <th className="border-0">TemplateId</th>
            </tr>
          </thead>
          <tbody>
            {props.collective.unt_members.map((member) => (
              <tr key={member.phoneno}>
                <td>{member.phoneno || ""}</td>
                <td>{member.groupId || ""}</td>
                <td>{member.memberType || ""}</td>
                <td>{member.templateId || ""}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
export default UntMembers;
