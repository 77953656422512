import React, { useState, useEffect } from "react";
import {
  Row,
  Card,
  Col,
  Table,
  Container,
  Button,
  Modal,
  ModalBody,
  ModalTitle,
  ModalFooter,
  Form,
} from "react-bootstrap";
import { formatPretty } from "helpers/dateHelper";
import MissionService from "./../services/mission.service";

function Mission(props) {
  const [missions, setMissions] = useState([]);
  const [error, setError] = useState(null);
  const [missionName, setSearchMission] = useState("");

  useEffect(async () => {
    setError(null);
    if (!props || !props.consumer || !props.consumer.profile) return;
    try {
      const missionsResult = await MissionService.GetMissions(
        props.consumer._id
      );
      setMissions(missionsResult);
    } catch (err) {
      setError(err.message);
    }
  }, [props.consumer]);
  const add8Date = (date) => {
    return new Date(new Date(date).getTime() + 8 * 60 * 60 * 1000);
  };
  return (
    <Container fluid>
      <Row>
        <Col>
          {error !== null ? (
            <div>{error}</div>
          ) : (
            <div>
              {missions.length !== 0 ? (
                <Card style={{ marginTop: 12 }}>
                  <Card.Header>
                    <Card.Title as="h4">Даалгавар</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <Col md="3">
                      <Form>
                        <Form.Control
                          className="md-2"
                          value={missionName}
                          onChange={(e) => {
                            setSearchMission(e.target.value);
                          }}
                          type="text"
                          placeholder="Даалгаврын нэр"
                          required
                        />
                      </Form>
                    </Col>
                    <Col>
                      <Table>
                        <thead>
                          <tr>
                            <th className="border-0">Даалгаврын нэр</th>
                            <th className="border-0">Байгууллага</th>
                            <th className="border-0">Даалгавар эхлэх огноо</th>
                            <th className="border-0">Даалгавар дуусах огноо</th>
                            <th className="border-0">Даалгаврын гүйцэтгэл</th>
                            <th className="border-0">
                              Даалгавар биелүүлсэн эсэх
                            </th>
                            <th className="border-0">
                              Даалгавар биелүүлсэн огноо
                            </th>
                            <th className="border-0">Урамшууллын оноо</th>
                          </tr>
                        </thead>
                        <tbody>
                          {missions !== undefined ? (
                            missions?.map((mission) => {
                              if (
                                missionName === "" ||
                                mission?.mission_id?.name
                                  .toLowerCase()
                                  .includes(missionName.toLowerCase())
                              )
                                return (
                                  <tr>
                                    <td className="border-0">
                                      {mission?.mission_id?.name}
                                    </td>
                                    <td className="border-0">
                                      {mission?.merchant?.name}
                                    </td>
                                    <td className="border-0">
                                      {formatPretty(
                                        add8Date(mission?.start_date)
                                      )}
                                    </td>
                                    <td className="border-0">
                                      {formatPretty(mission?.end_date)}
                                    </td>
                                    <td className="border-0">
                                      {`${mission?.progress} / ${mission?.target}`}
                                    </td>
                                    <td className="border-0">
                                      {mission?.is_completed === true
                                        ? "Тийм"
                                        : "Үгүй"}
                                    </td>
                                    <td className="border-0">
                                      {mission?.is_completed
                                        ? formatPretty(
                                            add8Date(mission?.updated_at)
                                          )
                                        : "Хараахан биелүүлээгүй"}
                                    </td>
                                    <td className="border-0">
                                      {mission?.is_completed
                                        ? mission?.prize_point
                                        : ""}
                                    </td>
                                  </tr>
                                );
                            })
                          ) : (
                            <></>
                          )}
                        </tbody>
                      </Table>
                    </Col>
                  </Card.Body>
                </Card>
              ) : (
                <div>Энэ хэрэглэгчид даалгавар байхгүй байна.</div>
              )}
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default Mission;
