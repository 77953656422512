import { BehaviorSubject } from "rxjs";
import axios from "axios";
// const baseUrl = 'http://localhost:4001'
// const baseUrl = 'http://10.10.10.88:4422'
// const baseUrl = "https://api1.upoint.mn:4433";
const baseUrl = "https://auth.upoint.mn";

// console.log("type of", localStorage.getItem("currentUser"));
const subscriber = new BehaviorSubject(
  JSON.parse(localStorage.getItem("currentUser"))
);
const updateCurrentUser = function (currentUser) {
  subscriber.next(currentUser);
};

const login = async function (username, password) {
  try {
    let response = await axios({
      url: baseUrl + "/internal/login",
      method: "POST",
      data: {
        username,
        password,
        client_id: "yWFWbYVuc9hywk",
        client_name: "UA CRM",
      },
    });
    // console.log("response", response.data);
    if (response.data.status_code === 0) {
      localStorage.setItem("currentUser", JSON.stringify(response.data));
      updateCurrentUser(response.data);
    } else if (
      response.data.error === "UserNotFound" ||
      "InvalidPassword" ||
      "UserDeactivated"
    ) {
      return response.data;
    } else {
      throw Error(response.error_description);
    }
  } catch (err) {
    throw err;
  }
};

const logout = async function () {
  try {
    localStorage.removeItem("currentUser");
    updateCurrentUser(null);
    return;
  } catch (err) {
    console.log(err);
  }
};

export const getUserInfo = async function (username, access_token) {
  try {
    let response = await axios({
      url: baseUrl + `/internal/admin/user/${username}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    if (response.data.status_code === 0) {
      return response.data.user;
    } else {
      throw Error(response.error);
    }
  } catch (err) {
    throw err;
  }
};

export const userUnblock = async function (username, access_token) {
  try {
    let response = await axios({
      url: baseUrl + `/internal/admin/user/${username}`,
      data: {
        username: username,
        status: true,
        client_id: "yWFWbYVuc9hywk",
      },
      method: "POST",
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });
    if (response.data.status_code === 0) {
      return response.data.user;
    } else {
      throw Error(response.error);
    }
  } catch (err) {
    throw err;
  }
};

const refreshToken = async () => {
  try {
    let response = await axios({
      url: baseUrl + `/internal/refresh`,
      data: {
        refresh_token: subscriber.value.refresh_token,
        client_id: "yWFWbYVuc9hywk",
      },
      method: "POST",
      headers: {
        Authorization: `Bearer ${subscriber.value.access_token}`,
      },
    });

    if (response.data.error === "TokenExpired") {
      logout();
    }

    if (response.data.status_code === 0) {
      localStorage.setItem("currentUser", JSON.stringify(response.data));
      updateCurrentUser(response.data);
    } else {
      throw Error(response.data.error_description);
    }
  } catch (err) {
    console.log("err", err);
    logout();
  }
};

export const authService = {
  login,
  logout,
  updateCurrentUser,
  getUserInfo,
  userUnblock,
  subscriber,
  refreshToken,
  // currentUserValue,
  get currentUser() {
    return subscriber.value;
  },
};
