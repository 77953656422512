import { React, useState } from "react";
import { Col, Button, Table, Form, Modal } from "react-bootstrap";

import merchantRequest from "../services/merchant.service";

function Xyp({ props }) {
  const [res, setRes] = useState([]);
  const [time, setTime] = useState([]);
  const [show, setShow] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");

  const handleClose = () => {
    setShow(false);
    setPhoneNumber("");
  };
  const handleShow = (e) => {
    e.preventDefault();
    setShow(true);
  };

  const submitXyp = async () => {
    const date1 = Date.now();
    let xyp = await merchantRequest.xyp("/check/xyp");
    setRes(xyp);
    const date2 = Date.now();
    const time = Math.abs(date2 - date1);
    setTime(`${time / 1000} секунд`);
  };

  const submitPartners = async () => {
    const date1 = Date.now();
    let partner = await merchantRequest.partner("/check/partner");
    setRes(partner);
    const date2 = Date.now();
    const time = Math.abs(date2 - date1);
    setTime(`${time / 1000} секунд`);
  };

  const submitTan = async (evt) => {
    handleClose();
    try {
      evt.preventDefault();
      const date1 = Date.now();
      debugger;
      let tan = await merchantRequest.tanCode(`/check/tan/${phoneNumber}`);

      debugger;
      await setRes(tan);
      const date2 = Date.now();
      const time = Math.abs(date2 - date1);
      setTime(`${time / 1000} секунд`);
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <Col style={{ padding: 0 }}>
      <div>
        <Button
          onClick={() => {
            submitXyp();
          }}
          style={{ marginRight: 12 }}
        >
          Xyp
        </Button>
        <Button
          style={{ marginRight: 12 }}
          onClick={() => {
            submitPartners();
          }}
        >
          Partners upoint
        </Button>
        <Button onClick={handleShow}>Tan code</Button>
        <Modal
          style={{ fontSize: "13px" }}
          show={show}
          onHide={handleClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Tan code</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={submitTan}>
              <Form.Group>
                <label>Утасны дугаар</label>
                <Form.Control
                  value={phoneNumber}
                  placeholder="Утасны дугаар оруулана уу"
                  type="number"
                  onChange={(e) => {
                    setPhoneNumber(e.target.value);
                  }}
                  required
                ></Form.Control>
              </Form.Group>
              <Button size="sm" type="submit" style={{ width: "100%" }}>
                Tan code
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      </div>
      <Table
        className="table-hover table-striped"
        style={{
          fontSize: "12px",
          padding: 0,
          maxWidth: "20rem",
        }}
      >
        <thead>
          <tr>
            <th className="border-0">Message</th>
            <th className="border-0">Request Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{res.message}</td>
            <td> {time} </td>
          </tr>
        </tbody>
      </Table>
    </Col>
  );
}

export default Xyp;
