import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  Card,
  Form,
  Navbar,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { getUserInfo } from "../../services/auth.service";
import RestService from "../../services/rest.service";
import AppContext from "store/AppContext";
function CreateEmployee(props) {
  const appContext = useContext(AppContext);
  const [roles, setRoles] = useState([]);
  const [didFetchUserInfo, setFetch] = useState(false);
  const initialState = {
    username: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_number: "",
    registration_number: "",
    position: "",
    role: "admin",
    password: "",
  };

  const [state, setState] = useState(initialState);

  useEffect(async () => {
    try {
      let roles = await RestService.getFilterRoles();
      setRoles(roles);
    } catch (err) {
      console.log(err);
      setRoles([]);
    }
  }, []);

  const onChange = async (evt) => {
    setState({
      ...state,
      [evt.target.name]: evt.target.value,
    });
    // if (evt.target.name === "email") {
    // const value = evt.target.value;
    // const username = value.substring(0, value.lastIndexOf("@"));
    // const email = value.substring(value.lastIndexOf("@"), value.length);
    // setFetch(false);
    // if (email === "@unitedalliance.mn") {
    //   try {
    //     // const userInfo = await getUserInfo(
    //     //   username,
    //     //   props.currentUser.access_token
    //     // );
    //     // setState({
    //     //   ...state,
    //     //   username: userInfo.username,
    //     //   [evt.target.name]: userInfo.email,
    //     // });
    //     // setFetch(true);
    //   } catch (err) {
    //     // setState({
    //     //   ...state,
    //     //   [evt.target.name]: value,
    //     // });
    //   }
    // } else {
    //   setState({
    //     ...state,
    //     [evt.target.name]: evt.target.value,
    //   });
    // }
    // } else {
    //   const value = evt.target.value;
    //   setState({
    //     ...state,
    //     [evt.target.name]: value,
    //   });
    // }
  };
  const onSubmit = async (evt) => {
    try {
      evt.preventDefault();
      if (appContext.currentUser.role == "admin") {
        await RestService.createUser(state);
        await props.refreshEmployees();
        props.notify(
          "tr",
          "success",
          `${state.username} эрхийг амжилттай үүсгэлээ!`
        );
      }
      if (appContext.currentUser.role != "admin") {
        props.notify("tr", "danger", "Та нэвтрэх эрх үүсгэх эрхгүй байна.");
      }
      setState(initialState);
    } catch (err) {
      props.notify("tr", "danger", err.message);
    }
  };

  return (
    <>
      <Card>
        <Card.Header>
          <Card.Title as="h4">Ажилтан үүсгэх</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={onSubmit}>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Email
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  placeholder="Email"
                  name="email"
                  type="text"
                  value={state.email}
                  onChange={onChange}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Нэвтрэх нэр
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  name="username"
                  type="text"
                  readOnly={didFetchUserInfo}
                  value={state.username}
                  onChange={onChange}
                  placeholder="Нэвтрэх нэр"
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Овог
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  name="last_name"
                  type="text"
                  value={state.last_name}
                  onChange={onChange}
                  placeholder="Овог"
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Нэр
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  name="first_name"
                  type="text"
                  value={state.first_name}
                  onChange={onChange}
                  placeholder="Нэр"
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Role
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  value={state.role}
                  name="role"
                  className="form-control"
                  onChange={onChange}
                >
                  {roles.map((role) => (
                    <option key={role.role} value={role.role}>
                      {role.role}
                    </option>
                  ))}
                  {/* <option value="mobile">Утасны дугаар</option>
                        <option value="reg_num">Регистрийн дугаар</option>
                        <option value="card_num">Картын дугаар</option> */}
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Утасны дугаар
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  placeholder="Утасны дугаар"
                  name="phone_number"
                  type="text"
                  value={state.phone_number}
                  onChange={onChange}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Регистр
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  placeholder="Регистр"
                  name="registration_number"
                  type="text"
                  value={state.registration_number}
                  onChange={onChange}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formPlaintextEmail">
              <Form.Label column sm="3">
                Албан тушаал
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  placeholder="Албан тушаал"
                  name="position"
                  type="text"
                  value={state.position}
                  onChange={onChange}
                  required
                />
              </Col>
            </Form.Group>
            {/* <div> */}
            <Button
              className="float-right"
              variant="primary"
              type="submit"
              size="sm"
            >
              Ажилтан үүсгэх
            </Button>
            {/* </div> */}
          </Form>
        </Card.Body>
      </Card>
    </>
  );
}

export default CreateEmployee;
