import axios from "axios";
//const baseUrl = "http://localhost:4001";
// const baseUrl = 'http://10.10.10.88:4422'
const baseUrl = "https://api1.upoint.mn:4433";
// const baseUrl = "http://localhost:4433";
// const baseUrl = "http://10.10.10.123:4001";

import { authService } from "./auth.service";
const request = async function (url, method, body) {
  try {
    let headers = {};
    if (authService.currentUser.access_token) {
      const currentUser = authService.currentUser;
      headers = { Authorization: `Bearer ${currentUser.access_token}` };
    }
    let response = await axios({
      url: baseUrl + url,
      method: method,
      data: body,
      headers: headers,
    });
    return response;
  } catch (err) {
    console.log(err, "request error");
    throw err;
  }
};

export default request;
