import request from "./request.service";

export default {
  GetMissions: async function (ConsumerID) {
    try {
      let response = await request(`/mission/${ConsumerID}`, "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  GetConsumerInfo: async function (ConsumerID) {
    try {
      let response = await request(`/consumer/${ConsumerID}`, "GET", {});
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
};
