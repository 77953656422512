import request from "./request.service";
import axios from "axios";
export default {
  getEclinicAllData: async function (limit = 10, offset = 0, consumerId) {
    try {
      let response = await request(
        `/eclinic/data?limit=${limit}&offset=${offset}&consumerId=${consumerId}`
      );

      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getEclinicPromoData: async function (limit = 10, offset = 0, consumerId) {
    try {
      let response = await request(
        `/eclinic/data?limit=${limit}&offset=${offset}&consumerId=${consumerId}`
      );
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(respoinse.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
};
