import { Button, Form, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import TransactionService from "../../services/transaction.service";
import GenericModal from "../Modal/GenericModal";
import XLSX from "xlsx";

function ProcessTransactionComponent({ consumer, notify }) {
  const initialState = {
    merchant: "5bd95078b92b980bb3f1fb91",
    amount: 0,
    deductAmount: 0,
    note: "",
    ticketNumber: "",
  };

  const [merchants, setMerchants] = useState([]);
  const [state, setState] = useState(initialState);
  const [excelData, setExcelData] = useState([]);

  const onChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const onFileChange = async ({ target }) => {
    if (target.files && target.files[0]) {
      const f = target.files[0];
      const data = await f.arrayBuffer();
      const workbook = XLSX.read(data);
      if (!workbook) {
        console.log(`there is no workbook. Please upload your workbook`);
        return;
      }
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      if (!worksheet) {
        console.log(
          `there is no sheet. Please upload your sheet and it must be the first sheet`
        );
        return;
      }
      const rowMajor = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const headers = ["mobile", "ticket_number", "note", "amount"];
      const datas = [];
      for (let i = 1; i < rowMajor.length; i = i + 1) {
        let data = {};
        for (let j = 0; j < 4; j = j + 1) {
          data[headers[j]] = rowMajor[i][j];
        }
        datas.push(data);
      }
      setExcelData(datas);
    }
  };

  const processTransaction = async () => {
    try {
      const result = await TransactionService.processTransaction(
        consumer.mobile,
        state.merchant,
        state.note,
        state.ticketNumber,
        state.amount,
        state.deductAmount
      );
      notify("tr", "success", `Гүйлгээ амжилттай хийгдлээ`);
      setState(initialState);
    } catch (err) {
      notify("tr", "danger", err.message);
    }
  };

  const processTransactionExcel = async () => {
    try {
      await TransactionService.processTransactionExcel(
        state.merchant,
        excelData
      );
      notify("tr", "success", `Гүйлгээ амжилттай хийгдлээ`);
      setState(initialState);
    } catch (err) {
      notify("tr", "danger", err.message);
    }
  };

  useEffect(async () => {
    try {
      setMerchants([]);
      let merchants = await TransactionService.getFilterMerchantsCRM();
      setMerchants(merchants);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setState(initialState);
  }, [consumer]);

  const getMerchantName = (merchantId) => {
    for (let merchant of merchants) {
      if (merchant._id === merchantId) {
        return merchant.name;
      }
    }
    return "";
  };

  return (
    <>
      <Form>
        <Row>
          <Col sm="2">
            {" "}
            <Form.Group controlId="formGridEmail">
              <Form.Label>Хэрэглэгчийн нэр</Form.Label>
              <Form.Control
                type="text"
                name={"amount"}
                value={consumer.profile.first_name || ""}
                readOnly
                plaintext
              />
            </Form.Group>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Утасны дугаар</Form.Label>
              <Form.Control
                type="number"
                name={"amount"}
                value={consumer.mobile || ""}
                plaintext
                readOnly
              />
            </Form.Group>
          </Col>
          <Col sm="3">
            <Form.Group controlId="formGridPassword">
              <Form.Label>Мерчант</Form.Label>
              <Form.Control
                as="select"
                value={state.merchant}
                name={"merchant"}
                className="form-control"
                onChange={onChange}
              >
                {merchants.map((merchant) => (
                  <option key={merchant._id} value={merchant._id}>
                    {merchant.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Ticket Number</Form.Label>
              <Form.Control
                type="text"
                onChange={onChange}
                placeholder="Ticket Number"
                name={"ticketNumber"}
                value={state.ticketNumber || ""}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Тайлбар</Form.Label>
              <Form.Control
                type="text"
                placeholder="Тайлбар"
                value={state.note}
                name="note"
                onChange={onChange}
                as="textarea"
                rows={3}
              />
            </Form.Group>
          </Col>
          <Col sm="2">
            <Form.Group controlId="formGridEmail">
              <Form.Label>Олгох оноо</Form.Label>
              <Form.Control
                type="number"
                name={"amount"}
                value={state.amount}
                onChange={onChange}
              />
            </Form.Group>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Хасах оноо</Form.Label>
              <Form.Control
                type="number"
                name={"deductAmount"}
                value={state.deductAmount}
                onChange={onChange}
              />
            </Form.Group>
            <GenericModal
              onClick={processTransaction}
              params={{}}
              action={"Гүйлгээ хийх"}
              title={`Та гүйлгээ хийхдээ итгэлтэй байна уу.`}
              body={`Та ${
                consumer.mobile
              } дугаартай хэрэглэгчийн дансанд ${getMerchantName(
                state.merchant
              )} байгууллаагаас ${state.amount} оноо нэмж ${
                state.deductAmount
              } хасах гүйлгээг хийхдээ итгэлтэй байна уу?`}
              type={"button"}
            />
          </Col>
          <Col sm="2"></Col>
          <Col sm="1">
            <Form.Group controlId="formGridExcel">
              <Form.Label>Олгох оноо</Form.Label>
              <Form.Control
                type="file"
                name={"amount"}
                onChange={onFileChange}
              />
            </Form.Group>
          </Col>
          <Col sm="1">
            <GenericModal
              sm="2"
              onClick={processTransactionExcel}
              params={{}}
              action={"Excel гүйлгээ хийх"}
              title={`Та гүйлгээ хийхдээ итгэлтэй байна уу.`}
              body={`Та ${
                consumer.mobile
              } дугаартай хэрэглэгчийн дансанд ${getMerchantName(
                state.merchant
              )} байгууллаагаас ${state.amount} оноо нэмж ${
                state.deductAmount
              } хасах гүйлгээг хийхдээ итгэлтэй байна уу?`}
              type={"button"}
            />
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default ProcessTransactionComponent;
