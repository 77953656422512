import { Button, Form, Col, Row } from "react-bootstrap";
import { useState, useEffect } from "react";
import PromoService from "../../services/promo.service";
import GenericModal from "../Modal/GenericModal";
function IssueSpin({ consumer, notify }) {
  const initialState = {
    chanceCount: 0,
    note: "",
    ticketNumber: "",
  };

  const [promotions, setPromotions] = useState([]);
  const [state, setState] = useState(initialState);
  const [chosenPromotion, setChosenPromotion] = useState();

  const onChange = (evt) => {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const issueSpinChance = async () => {
    try {
      await PromoService.issueSpinChance(
        consumer._id,
        chosenPromotion,
        state.ticketNumber,
        state.chanceCount,
        state.note
      );
      notify(
        "tr",
        "success",
        `${consumer.mobile} дугаартай хэрэглэгчид ${state.chanceCount} хүрд эргүүлэх эрх олголоо.`
      );
      setState(initialState);
    } catch (err) {
      notify("tr", "danger", err.message);
    }
  };

  useEffect(async () => {
    try {
      let promos = await PromoService.getSpinPromotions();
      setChosenPromotion(promos[0]._id);
      setPromotions(promos);
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    setState(initialState);
  }, [consumer]);

  return (
    <>
      <Form>
        <Row>
          <Col sm="3">
            <Form.Group controlId="formGridPassword">
              <Form.Label>Урамшуулал</Form.Label>
              <Form.Control
                as="select"
                value={chosenPromotion}
                name={"promotion"}
                className="form-control"
                onChange={(e) => {
                  setChosenPromotion(e.target.value);
                }}
              >
                {promotions.map((promotion) => (
                  <option key={promotion._id} value={promotion._id}>
                    {promotion.promo_name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formGridEmail">
              <Form.Label>Ticket Number</Form.Label>
              <Form.Control
                type="text"
                onChange={onChange}
                placeholder="Ticket Number"
                name={"ticketNumber"}
                value={state.ticketNumber || ""}
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Тайлбар</Form.Label>
              <Form.Control
                type="text"
                placeholder="Тайлбар"
                value={state.note}
                name="note"
                onChange={onChange}
                as="textarea"
                rows={3}
              />
            </Form.Group>
          </Col>
          <Col sm="2">
            <Form.Group controlId="formGridEmail">
              <Form.Label>Нөхөн олгох эрхийн тоо</Form.Label>
              <Form.Control
                type="number"
                name={"chanceCount"}
                value={state.chanceCount}
                onChange={onChange}
              />
            </Form.Group>
            <GenericModal
              onClick={issueSpinChance}
              params={{}}
              action={"Эрх олгох"}
              title={`Та эрх олгохдоо итгэлтэй байна уу.`}
              body={`Та ${consumer.mobile} дугаартай хэрэглэгчид эрх олгохдоо итгэлтэй байна уу?`}
              type={"button"}
            />
          </Col>
          <Col sm="1"></Col>
        </Row>
      </Form>
    </>
  );
}

export default IssueSpin;
