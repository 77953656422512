import React, { Component } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { useState } from "react";
import { Button, Nav } from "react-bootstrap";

import logo from "assets/img/reactlogo.png";

function Sidebar({ color, image, routes, views }) {
  const [sidebarOpen, setsidebarOpen] = useState(true);
  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  const switcher = () => {
    setsidebarOpen(!sidebarOpen);
  };
  return (
    <>
      <button
        className="sidebar-switch"
        onClick={() => {
          if (sidebarOpen) {
            document.getElementsByClassName(
              "main-panel"
            )[0].style.width = `100%`;
            document
              .getElementsByClassName("sidebar-switch")[0]
              .setAttribute(
                "style",
                "border-color:gray; border-style:solid; color:gray; margin:22px 0px 0px 0px"
              );
          } else {
            document
              .getElementsByClassName("main-panel")[0]
              .setAttribute("style", "width:$sidebar-width !important");
            document
              .getElementsByClassName("sidebar-switch")[0]
              .setAttribute(
                "style",
                "border-color:white; color:white; margin: 5px 0px 0px 210px"
              );
          }
          switcher();
        }}
      >
        {sidebarOpen ? (
          "x"
        ) : (
          <span>
            <svg
              height="20px"
              id="Layer_1"
              stroke="currentColor"
              viewBox="0 0 32 32"
              width="20px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4,10h24c1.104,0,2-0.896,2-2s-0.896-2-2-2H4C2.896,6,2,6.896,2,8S2.896,10,4,10z M28,14H4c-1.104,0-2,0.896-2,2 s0.896,2,2,2h24c1.104,0,2-0.896,2-2S29.104,14,28,14z M28,22H4c-1.104,0-2,0.896-2,2s0.896,2,2,2h24c1.104,0,2-0.896,2-2 S29.104,22,28,22z" />
            </svg>
          </span>
        )}
      </button>
      <div
        className={`${sidebarOpen ? "sidebar" : "sidebar-closed"}`}
        data-image={image}
        data-color={color}
      >
        <div
          className="sidebar-background"
          style={{
            backgroundImage: "url(" + image + ")",
          }}
        />
        <div className="sidebar-wrapper">
          <div className="logo d-flex align-items-center justify-content-start">
            <a href="" className="simple-text logo-mini mx-1">
              <div className="logo-img">
                <img src={require("assets/img/upoint.png").default} alt="..." />
              </div>
            </a>
            <a className="simple-text" href="/">
              U-Point
            </a>
          </div>
          <Nav>
            {routes.map((prop, key) => {
              if (
                !prop.redirect &&
                views.includes(prop.view) &&
                !["change_password", "employee_log"].includes(prop.view)
              )
                return (
                  <li
                    className={activeRoute(prop.layout + prop.path)}
                    key={key}
                  >
                    <NavLink
                      to={prop.layout + prop.path}
                      className="nav-link"
                      activeClassName="active"
                    >
                      <i className={prop.icon} />
                      <p>{prop.name}</p>
                    </NavLink>
                  </li>
                );
              return null;
            })}
          </Nav>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
