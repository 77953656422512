import request from "./request.service";

export const getScanType = async () => {
  const result = await request(`/qrs/get_scan_type`);
  return result.data;
};

export const getPurchases = async (
  offset,
  type,
  bill_number,
  pos_id,
  start_date,
  end_date,
  status
) => {
  let url = `/qrs/getPurchases?`;
  if (type !== undefined) url += `type=${type}&`;
  if (bill_number !== undefined) url += `bill_number=${bill_number}&`;
  if (pos_id !== undefined) url += `pos_id=${pos_id}&`;
  if (start_date !== undefined) url += `start_date=${start_date}&`;
  if (end_date !== undefined) url += `end_date=${end_date}&`;
  if (status !== undefined) url += `status=${status}&`;
  url += `offset=${offset}`;
  const result = await request(url);

  return result.data;
};
