import { useState, useEffect } from "react";
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import PromoService from "../services/promo.service";
import Loading from "../components/Util/Loading";
import { formatBeauty, formatPretty } from "../helpers/dateHelper";
import moment from "moment";
import Pagination from "components/Util/Pagination";

function Coupon(props) {
  const [loading, setLoading] = useState(false);
  const [giftLoading, setGiftLoading] = useState(false);
  const [recvLoading, setRecvLoading] = useState(false);

  const [coupons, setCoupons] = useState([]);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 50,
    count: 0,
  });
  const [checkResult, setCheckResult] = useState(null);
  const [couponCode, setCouponCode] = useState("");

  const [giftlist, setGiftlist] = useState([]);
  const [giftPagination, setgiftPagination] = useState();

  const [recvlist, setRecvList] = useState([]);
  const [recvPagination, setRecvPagination] = useState();

  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };

  const getCoupons = async (offset = 0, limit = 50) => {
    try {
      setLoading(true);
      let data = await PromoService.getConsumerCoupons(
        props?.consumer._id,
        offset,
        limit
      );
      setCoupons(data.coupons);
      setPagination({
        offset: offset,
        limit: limit,
        count: data.pagination.items,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const checkCoupon = async () => {
    try {
      setLoading(true);
      setCheckResult(null);
      let data = await PromoService.checkCoupon(couponCode);
      props.notify("tr", "success", `${data.coupon.name} нэртэй купон олдлоо.`);
      console.log(data);
      setCheckResult(data);
    } catch (err) {
      props.notify("tr", "danger", err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const giftCoupon = async (offset = 0, limit = 10) => {
    try {
      setGiftlist([]);
      const result = await PromoService.giftCoupon(
        props?.consumer?._id,
        offset,
        limit,
        "gifter"
      );
      setGiftlist(result.list);
      setgiftPagination(result.pagination);
    } catch (err) {
      props.notify("tr", "danger", err.message);
      console.log(err);
    } finally {
      setGiftLoading(false);
    }
  };
  const receiveCoupon = async (offset = 0, limit = 10) => {
    try {
      setRecvLoading(true);
      const result = await PromoService.giftCoupon(
        props?.consumer?._id,
        offset,
        limit,
        "receiver"
      );
      setRecvList(result.list);
      setRecvPagination(result.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setRecvLoading(false);
    }
  };
  useEffect(() => {
    if (props.consumer) {
      setLoading(true);
      getCoupons(0, 50);

      setGiftLoading(true);
      giftCoupon();

      setRecvLoading(true);
      receiveCoupon();

      setCheckResult(null);
      setCoupons([]);
      setCouponCode("");
    }
  }, [props?.consumer]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <Card className="strpied-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Купон шалгах</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form>
                  <Row>
                    <Col sm="3">
                      <Form>
                        <Form.Row className="align-items-center">
                          <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Купон код</Form.Label>
                            <Form.Control
                              type="text"
                              value={couponCode}
                              onChange={(e) => {
                                setCouponCode(e.target.value);
                              }}
                            />
                          </Form.Group>
                          <Form.Group
                            as={Col}
                            sm="3"
                            controlId="formGridPassword"
                          >
                            <Button
                              style={{ marginTop: "20px" }}
                              variant="primary"
                              className="btn btn-sm"
                              size="md"
                              onClick={checkCoupon}
                            >
                              Шалгах
                            </Button>
                          </Form.Group>
                        </Form.Row>
                      </Form>
                    </Col>
                    <Col sm="9">
                      <Table
                        className="table-hover"
                        style={{
                          fontSize: "12px",
                        }}
                      >
                        <thead>
                          <tr>
                            <th className="border-0">Утасны дугаар</th>
                            <th className="border-0">Купоны нэр</th>
                            <th className="border-0">Худалдан авсан огноо</th>
                            <th className="border-0">Дуусах огноо</th>
                            <th className="border-0">Байгууллагын нэр</th>
                            <th className="border-0">Ашигласан эсэх</th>
                            <th className="border-0">Ашигласан ажилтан</th>
                            <th className="border-0">Ашигласан огноо</th>
                          </tr>
                        </thead>
                        {checkResult === null ? null : (
                          <tbody style={{ wordBreak: "break-all" }}>
                            <tr>
                              <td>{checkResult.consumer.mobile || ""}</td>

                              <td>{checkResult.coupon.name || ""}</td>
                              <td>
                                {formatPretty(checkResult.coupon.created_at)}
                              </td>
                              <td>
                                {formatPretty(checkResult.coupon.expire_date)}
                              </td>
                              <td>{checkResult.merchant.name}</td>
                              <td
                                style={
                                  checkResult.coupon.is_redeemed
                                    ? styleGreen
                                    : styleRed
                                }
                              >
                                {checkResult.coupon.is_redeemed
                                  ? "Ашигласан"
                                  : "Ашиглаагүй"}
                              </td>
                              <td>{checkResult.coupon.redeemed_by || ""}</td>
                              <td>
                                {(checkResult.coupon.redeemed_at &&
                                  formatPretty(
                                    checkResult.coupon.redeemed_at
                                  )) ||
                                  ""}
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </Col>
                  </Row>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Купон Бэлэглэсэн Түүх</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Бэлэглэсэн дугаар</th>
                      <th className="border-0">Бэлэглэгсэн Огноо</th>
                      <th className="border-0">Купон нэр</th>
                      <th className="border-0">Купон үүссэн огноо</th>
                      <th className="border-0">Ашигласан эсэх</th>
                      <th className="border-0">Ашигласан огноо</th>
                      <th className="border-0">Ашигласан тэмдэглэл</th>
                    </tr>
                  </thead>
                  <tbody>
                    {giftlist.length > 0
                      ? giftlist.map((el) => (
                          <tr>
                            <td>{el?.mobile}</td>
                            <td>
                              {moment(el?.created_at).format(
                                "YYYY-MM-DD HH:MM"
                              )}
                            </td>
                            <td>{el?.name}</td>
                            <td>
                              {moment(el?.coupon_created).format(
                                "YYYY-MM-DD HH:MM"
                              )}
                            </td>
                            <td>{el?.is_redeemed ? "Тийм" : ""}</td>
                            <td>{el?.redeemed_at}</td>
                            <td>{el?.redeem_note}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </Card.Body>

              <Loading loading={giftLoading} />
            </Card>
            <Pagination pagination={giftPagination} onChange={giftCoupon} />
          </Col>
        </Row>

        <Row>
          <Col md="12">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Купон Бэлгэнд Авсан Түүх</Card.Title>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Бэлэглэсэн дугаар</th>
                      <th className="border-0">Бэлэглэсэн Огноо</th>
                      <th className="border-0">Купон нэр</th>
                      <th className="border-0">Купон үүссэн огноо</th>
                      <th className="border-0">Ашигласан эсэх</th>
                      <th className="border-0">Ашигласан огноо</th>
                      <th className="border-0">Ашигласан тэмдэглэл</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recvlist.length > 0
                      ? recvlist.map((el) => (
                          <tr>
                            <td>{el?.mobile}</td>
                            <td>
                              {moment(el?.created_at).format(
                                "YYYY-MM-DD HH:MM"
                              )}
                            </td>
                            <td>{el?.name}</td>
                            <td>
                              {moment(el?.coupon_created).format(
                                "YYYY-MM-DD HH:MM"
                              )}
                            </td>
                            <td>{el?.is_redeemed ? "Тийм" : ""}</td>
                            <td>{el?.redeemed_at}</td>
                            <td>{el?.redeem_note}</td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </Table>
              </Card.Body>
              <Loading loading={recvLoading} />
            </Card>
            <Pagination pagination={recvPagination} onChange={receiveCoupon} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="striped-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Купон жагсаалт</Card.Title>
                {/* <Form>
                  <Form.Row className="align-items-center">
                    <Form.Group as={Col} sm="3" controlId="formGridPassword">
                      <Button
                        style={{ marginTop: "20px" }}
                        variant="primary"
                        className="btn btn-sm"
                        size="md"
                        onClick={() => {
                        }}
                      >
                        Хайх
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Form> */}
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Худалдан авсан огноо</th>
                      <th className="border-0">Байгууллага</th>
                      <th className="border-0">Купоны төрөл</th>
                      <th className="border-0">Купоны нэр</th>
                      <th className="border-0">Купоны код</th>
                      <th className="border-0">Дуусах огноо</th>
                      <th className="border-0">Ашигласан эсэх</th>
                      <th className="border-0">Ашигласан огноо</th>
                      <th className="border-0">Ашигласан ажилтан</th>
                      <th className="border-0">Идэвхитэй эсэх</th>
                    </tr>
                  </thead>
                  <tbody style={{ wordBreak: "break-all" }}>
                    {coupons.map((coupon, key) => (
                      <tr key={key}>
                        <td>{formatBeauty(coupon.created_at)}</td>
                        <td>{coupon?.merchant?.name}</td>
                        <td>{coupon?.aud_type}</td>
                        <td>{coupon?.product.name_en}</td>
                        <td>{coupon?.coupon_code}</td>
                        <td>{formatBeauty(coupon.expire_date)}</td>
                        <td style={coupon.is_redeemed ? styleGreen : styleRed}>
                          {coupon.is_redeemed ? "Ашигласан" : "Ашиглаагүй"}
                        </td>
                        <td>{coupon.redeemed_at}</td>
                        {coupon.redeemed_by !== null ? (
                          <td>{coupon.redeemed_by}</td>
                        ) : (
                          <td></td>
                        )}
                        <td style={coupon.is_active ? styleGreen : styleRed}>
                          {coupon.is_active
                            ? "Ашиглах боломжтой"
                            : "Ашиглах боломжгүй"}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <Loading loading={loading} />
              </Card.Body>
            </Card>
            <Pagination pagination={pagination} onChange={getCoupons} />
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Coupon;
