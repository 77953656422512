import UserInfo from "views/UserInfo.js";
import Transactions from "views/Transactions.js";
import ConsumerLog from "views/ConsumerLog.js";
import Log from "views/Log.js";
import Settings from "views/Settings.js";
import EmployeeLog from "views/EmployeeLog.js";
import ChangePassword from "views/ChangePassword.js";
import Termination from "views/Termination.js";
import FamilyEvent from "views/FamilyEvent.js";
import Coupon from "views/Coupon.js";
import Block from "views/Block.js";
import Seal from "views/Seal.js";
import Spin from "views/Spin";
import Badge from "views/Badge";
import Merchant from "./views/Merchant";
import CheckTransaction from "views/CheckTransaction";
import EmployeeLoyalty from "views/EmployeeLoyalty";
import Mission from "views/Mission";
import GraceryStores from "views/GroceryStores";
import Eclinic from "views/Eclinic";
import CardConnection from "views/CardConnection";
import Lottery from "views/Lottery";
import SLoyalty from "views/SLoyalty";

const dashboardRoutes = [
  {
    path: "/user",
    name: "Хэрэглэгч",
    icon: "nc-icon nc-circle-09",
    component: UserInfo,
    layout: "/admin",
    view: "crm_user",
  },
  {
    path: "/transaction",
    name: "Гүйлгээ",
    icon: "nc-icon nc-credit-card",
    component: Transactions,
    layout: "/admin",
    view: "crm_transaction",
  },
  {
    path: "/consumer_log",
    name: "Хэрэглэгчийн түүх",
    icon: "fa fa-history fa-2x",
    component: ConsumerLog,
    layout: "/admin",
    view: "crm_consumer_log",
  },
  {
    path: "/promo_coupon",
    name: "Купон",
    icon: "fa fa-gift fa-2x",
    component: Coupon,
    layout: "/admin",
    view: "crm_promo_coupon",
  },
  {
    path: "/card_connection",
    name: "Карт",
    icon: "fa fa-car fa-2x",
    component: CardConnection,
    layout: "/admin",
    view: "crm_consumer_log",
  },
  {
    path: "/sloyalty",
    name: "Subscription Loyalty",
    icon: "fa fa-key fa-2x",
    component: SLoyalty,
    layout: "/admin",
    view: "crm_user",
  },
  {
    path: "/eclinic",
    name: "EClinic",
    icon: "fa fa-hospital",
    component: Eclinic,
    layout: "/admin",
    view: "crm_user",
  },
  {
    path: "/mission",
    name: "Mission",
    icon: "fab fa-medium-m",
    component: Mission,
    layout: "/admin",
    view: "crm_mission",
  },
  {
    path: "/lottery",
    name: "Lottery",
    icon: "fas fa-ticket-alt",
    component: Lottery,
    layout: "/admin",
    view: "crm_lottery",
  },
  {
    path: "/termination",
    name: "Termination",
    icon: "fa fa-cog",
    component: Termination,
    layout: "/admin",
    view: "crm_termination",
  },
  {
    path: "/family_event",
    name: "FamilyEvent",
    icon: "fa fa-cog",
    component: FamilyEvent,
    layout: "/admin",
    view: "crm_family_event",
  },
  {
    path: "/spin",
    name: "Хүрд",
    icon: "fa fa-dot-circle fa-2x",
    component: Spin,
    layout: "/admin",
    view: "crm_spin",
  },
  {
    path: "/badge",
    name: "Badge",
    icon: "fa fa-certificate",
    component: Badge,
    layout: "/admin",
    view: "crm_badge",
  },
  {
    path: "/merchant",
    name: "Merchant",
    icon: "fas fa-warehouse",
    component: Merchant,
    layout: "/admin",
    view: "crm_merchant",
  },
  {
    path: "/check",
    name: "Check",
    icon: "far fa-check-circle",
    component: CheckTransaction,
    layout: "/admin",
    view: "crm_check",
  },
  {
    path: "/loyalty",
    name: "Loyalty",
    icon: "fas fa-hand-holding-heart",
    component: EmployeeLoyalty,
    layout: "/admin",
    view: "crm_loyalty",
  },
  {
    path: "/log",
    name: "Log",
    icon: "fa fa-bars",
    component: Log,
    layout: "/admin",
    view: "crm_mission",
  },
  {
    path: "/block",
    name: "Блок",
    icon: "fa fa-ban",
    component: Block,
    layout: "/admin",
    view: "crm_block",
  },
  {
    path: "/seal",
    name: "Битүүмж",
    icon: "fa fa-stamp",
    component: Seal,
    layout: "/admin",
    view: "crm_seal",
  },
  {
    path: "/settings",
    name: "Тохиргоо",
    icon: "fa fa-cog",
    component: Settings,
    layout: "/admin",
    view: "crm_settings",
  },
  {
    path: "/employee_log",
    name: "Ажилтны лог",
    icon: "fa fa-history fa-2x",
    component: EmployeeLog,
    layout: "/admin",
    view: "crm_employee_log",
  },
  {
    path: "/change_password",
    name: "Тохиргоо",
    icon: "fa fa-cog",
    component: ChangePassword,
    layout: "/admin",
    view: "crm_change_password",
  },
];

export default dashboardRoutes;
