import { formatPretty } from "helpers/dateHelper";
import { Table } from "react-bootstrap";
import consumerService from "services/consumer.service";
import { useContext } from "react";
import AppContext from "store/AppContext";
import GenericModal from "../../components/Modal/GenericModal";
function ConsumerCards(props) {
  const appContext = useContext(AppContext);
  const cardActive = async (card_number) => {
    try {
      await consumerService.updateCardToActivate(card_number);
      appContext.notify("tr", "success", "Карт ИДЭВХИТЭЙ төлөв рүү шилжлээ");
      () => {
        appContext.refreshConsumer();
      };
    } catch (err) {
      props.notify("tr", "danger", err.message);
    }
  };

  const cardInactive = async (card_number) => {
    try {
      await consumerService.updateCardToInactivate(card_number);
      appContext.notify("tr", "success", "Карт ИДЭВХГҮЙ төлөв рүү шилжлээ");
      appContext.refreshConsumer();
    } catch (err) {
      console.log(err);
      props.notify("tr", "danger", err.message);
    }
  };
  if (
    !props.consumer ||
    !props.consumer.profile ||
    !props.consumer.cards ||
    props.consumer.cards.length === 0
  ) {
    return null;
  }
  return (
    <>
      <Table
        className="table-hover table-striped"
        style={{
          fontSize: "12px",
        }}
      >
        <thead>
          <tr>
            <th className="border-0">Байгууллага</th>
            <th className="border-0">Карт дугаар</th>
            <th className="border-0">Serial дугаар</th>
            <th className="border-0">NFC</th>
            <th className="border-0">Утас</th>
            <th className="border-0">Төлөв</th>
            <th className="border-0">Үлдэгдэл</th>
            <th className="border-0">Төрөл</th>
            <th className="border-0">Бүртгэсэн огноо</th>
            <th className="border-0">Карт</th>
          </tr>
        </thead>
        <tbody>
          {props.consumer.cards.map((card) => (
            <tr key={card._id}>
              <td>{card.partner && card.partner.name}</td>
              <td>{card.number}</td>
              <td>{card.serial_number}</td>
              <td>{card.nfc_number}</td>
              <td>{card.mobile}</td>
              <td>
                {card.partner.name.length === 19 && card.status === 1 ? (
                  <div>Идэвхитэй</div>
                ) : card.partner.name.length === 19 && card.status === 2 ? (
                  <div>Идэвхигүй</div>
                ) : card.partner.name.length === 19 && card.status === 99 ? (
                  <div>Ашиглах боломжгүй</div>
                ) : (
                  card.status
                )}
              </td>
              <td>{card.balance}</td>
              <td>{card.card_type}</td>
              <td>
                {(card.assigned_date && formatPretty(card.assigned_date)) || ""}
              </td>
              <td>
                {typeof card.partner?.name && card.status === 2 ? (
                  <div>
                    <GenericModal
                      onClick={() => cardActive(card.number)}
                      params={{}}
                      action={"Карт нээх"}
                      title={"Карт идэвхтэй төлөвт шилжүүлэх"}
                      body={`Та ${props.consumer.mobile} хэрэглэгчийн картыг идэвхтэй болгох уу?`}
                    />
                  </div>
                ) : (card.partner?.name && card.card_type === 1) ||
                  (card.partner?.name && card.card_type === 2) ? (
                  <div>
                    <GenericModal
                      onClick={() => cardInactive(card.number)}
                      params={{}}
                      action={"Карт хаах"}
                      title={"Карт идхэвгүй төлөвт шилжүүлэх"}
                      body={`Та ${props.consumer.mobile} хэрэглэгчийн картыг идэвхгүй болгох уу? хэрэглэгчийн картыг идэвхгүй болгосоноор гүйлгээ хийх боломжгүй болохыг анхаарана уу!`}
                    />
                  </div>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
export default ConsumerCards;
