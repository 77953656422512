import request from "./request.service";
import { getStartOfDate, getEndOfDate } from "../helpers/dateHelper";
export default {
  getEmployeeLog: async function (startDate, endDate, offset, limit) {
    try {
      const response = await request("/log/employee", "POST", {
        start_date: startDate,
        end_date: endDate,
        offset: offset,
        limit: limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getConsumerLog: async function (
    consumerId,
    startDate,
    endDate,
    offset,
    limit
  ) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request("/log/consumer", "POST", {
        consumer_id: `${consumerId}`,
        start_date: startPretty,
        end_date: endPretty,
        offset: offset,
        limit: limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getEmployeeLog: async function (employee, startDate, endDate, offset, limit) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request("/employee/logs", "POST", {
        employee: employee,
        start_date: startPretty,
        end_date: endPretty,
        offset: offset,
        limit: limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  downloadEmployeeLog: async function (employee, startDate, endDate) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request("/employee/logs/download", "POST", {
        employee: employee,
        start_date: startPretty,
        end_date: endPretty,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getSentSMSList: async function (mobile, startDate, endDate, offset, limit) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request("/sms/sent", "POST", {
        mobile: mobile,
        start_date: startPretty,
        end_date: endPretty,
        offset: offset,
        limit: limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getNutSentSMSList: async function (
    mobile,
    startDate,
    endDate,
    offset,
    limit
  ) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request("/sms/sent_nut", "POST", {
        mobile: mobile,
        start_date: startPretty,
        end_date: endPretty,
        offset: offset,
        limit: limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getNotiList: async function (consumer_id, startDate, endDate, offset, limit) {
    try {
      const response = await request("/sms/sent_noti", "POST", {
        consumer_id: `${consumer_id}`,
        start_date: getStartOfDate(startDate),
        end_date: getEndOfDate(endDate),
        offset: offset,
        limit: limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getSMSList: async function (mobile, startDate, endDate, offset, limit) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request("/sms/mobile", "POST", {
        mobile: mobile,
        start_date: startPretty,
        end_date: endPretty,
        offset: offset,
        limit: limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getSMSLog: async function (mobile, startDate, endDate, offset, limit) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request("/sms/log", "POST", {
        mobile: mobile,
        start_date: startPretty,
        end_date: endPretty,
        offset: offset,
        limit: limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  getReceivedSMSList: async function (
    mobile,
    startDate,
    endDate,
    offset,
    limit
  ) {
    try {
      let startPretty = getStartOfDate(startDate);
      let endPretty = getEndOfDate(endDate);
      const response = await request("/sms/received", "POST", {
        mobile: mobile,
        start_date: startPretty,
        end_date: endPretty,
        offset: offset,
        limit: limit,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  sendSMS: async function (mobile, text) {
    try {
      const response = await request("/sms/send", "POST", {
        mobile,
        text,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
  sendNoti: async function (consumer_id, body) {
    try {
      const response = await request(`/sms/send_noti`, "POST", {
        consumer_id: `${consumer_id}`,
        body: `${body}`,
      });
      if (response.data.code === 0) {
        return response.data.data;
      } else {
        throw Error(response.data.message);
      }
    } catch (err) {
      throw err;
    }
  },
};
