import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Container,
  Row,
  Col,
  Form,
  Button,
} from "react-bootstrap";
import PromoService from "../../services/promo.service";
import Loading from "components/Util/Loading";
import { formatPretty } from "../../helpers/dateHelper";

function Badge(props) {
  const [badges, setBadges] = useState([]);
  const [loading, setLoading] = useState(false);
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };

  const getBadges = async () => {
    try {
      setLoading(true);
      setBadges([]);
      let data = await PromoService.consumerBadges(props.consumer._id);
      setBadges(data.badges);
    } catch (err) {
      props.notify("tr", "danger", err.message);
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(async () => {
  //     try {
  //         await getBadges()
  //     } catch (error) {
  //         console.log(error)
  //     }
  // }, [])

  useEffect(() => {
    getBadges();
    // setBadges([])
  }, [props.consumer]);

  // const handleSearch = async () => {
  //     try {
  //         await getbadges()
  //     } catch (err) {}
  // }

  return (
    <>
      <Card className="strpied-tabled-with-hover">
        <Card.Header>
          <Card.Title as="h4">Харагдаж буй цолууд </Card.Title>
          <Form>
            <Form.Row className="align-items-center">
              <Form.Group as={Col} sm="3" controlId="formGridPassword">
                <Button
                  style={{ marginTop: "20px" }}
                  variant="primary"
                  className="btn btn-sm"
                  size="md"
                  onClick={getBadges}
                >
                  Хайх
                </Button>
              </Form.Group>
            </Form.Row>
          </Form>
          {/* <Row>
                                    <Col md="8">
                                        <Filter
                                            startDate={startDate}
                                            setStartDate={setStartDate}
                                            endDate={endDate}
                                            setEndDate={setEndDate}
                                            merchants={merchantList}
                                            chosenMerchant={chosenMerchant}
                                            setChosenMerchant={setChosenMerchant}
                                            badgeType={badgeType}
                                            setbadgeType={setbadgeType}
                                            userType={userType}
                                            setUserType={setUserType}
                                            handleSearch={handleSearch}
                                        />
                                    </Col>
                                </Row> */}
        </Card.Header>
        <Card.Body className="table-full-width table-responsive px-0">
          <Table
            className="table-hover"
            style={{
              fontSize: "12px",
            }}
          >
            <thead>
              <tr>
                <th>Зураг</th>
                <th className="border-0">Цол</th>
                <th className="border-0">Байгууллага</th>
                <th className="border-0">Статус</th>
              </tr>
            </thead>

            <tbody style={{ wordBreak: "break-all" }}>
              {badges.map((badge) => (
                <tr key={badge._id}>
                  <td>
                    <img
                      src={badge.merchant_logo}
                      height="40"
                      width="40"
                      class="img-fluid img-thumbnail"
                      alt="Sheep"
                    />
                  </td>
                  <td>{badge.name}</td>
                  <td>{badge.merchant_name_mn}</td>
                  <td style={badge.status ? styleGreen : styleRed}>
                    {badge.status ? "active" : "inactive"}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Loading loading={loading} />
        </Card.Body>
      </Card>
    </>
  );
}

export default Badge;
