import { useState, useEffect } from "react";
import { Card, Table, Row, Col, Button, Form } from "react-bootstrap";
import TransactionService from "../../services/transaction.service";
import restService from "services/rest.service";
import Loading from "../Util/Loading";
import Select from "react-select";
import Pagination from "../Util/Pagination";
import {
  formatPretty,
  formatBeauty,
  getCurrentDate,
  getLastWeekDate,
} from "../../helpers/dateHelper";
import fileDownload from "js-file-download";
import merchantService from "services/merchant.service";
import AppContext from "store/AppContext";
import { useContext } from "react";
function EmployeeTransactions(props) {
  const [startDate, setStartDate] = useState(getLastWeekDate());
  const [endDate, setEndDate] = useState(getCurrentDate());
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState();
  const [employees, setEmployees] = useState([]);
  const [employee, setEmployee] = useState("any");
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };
  const appContext = useContext(AppContext);
  const handleSearch = async () => {
    try {
      await getTransactions();
    } catch (err) {}
  };

  const handleDownload = async () => {
    try {
      await downloadTransactions();
    } catch (err) {}
  };

  const getEmployees = async () => {
    let employees = await restService.getUsers();
    employees = employees.map((emp) => {
      return {
        value: emp.username,
        label: emp.username,
      };
    });
    employees.unshift({ label: "Бүгд", value: "any" });
    return employees;
  };

  const getTransactions = async (offset, limit) => {
    try {
      setLoading(true);
      setTransactions([]);
      setPagination(null);
      let data;
      if (appContext.currentUser.role == "admin") {
        data = await TransactionService.getEmployeeTransactions(
          employee,
          startDate,
          endDate,
          offset,
          limit
        );
      } else {
        data = await TransactionService.getEmployeeTransactions(
          appContext.currentUser.sub,
          startDate,
          endDate,
          offset,
          limit
        );
        if (appContext.currentUser.sub != employee) {
          appContext.notify(
            "tr",
            "success",
            "Та өөрийн гүйлгээг шалгах эрхтэй"
          );
        }
      }
      setTransactions(data.transactions);
      setPagination(data.pagination);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const downloadTransactions = async () => {
    try {
      const { fileName } = await TransactionService.downloadTransactions(
        employee,
        startDate,
        endDate
      );
      let excel = await merchantService.getFile(fileName);
      fileDownload(excel, fileName);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(async () => {
    setEmployees(await getEmployees());
    setTransactions([]);
    setStartDate(getLastWeekDate());
    setEndDate(getCurrentDate());
    setLoading(false);
  }, [props.consumer]);

  return (
    <>
      <Col md="12">
        <Card className="striped-tabled-with-hover">
          <Card.Header>
            <Card.Title as="h4">Ажилтны хийсэн гүйлгээнүүд</Card.Title>
            <Form>
              <Form.Row className="align-items-center">
                <Form.Group as={Col} sm="2" controlId="formGridEmail">
                  <Form.Label>Эхлэх огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group as={Col} sm="2" controlId="formGridPassword">
                  <Form.Label>Дуусах огноо</Form.Label>
                  <Form.Control
                    type="date"
                    format="yyyy-mm-dd"
                    placeholder={endDate || "дуусах огноо"}
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </Form.Group>
                <Form.Group as={Col} sm="2" controlId="formGridPassword">
                  <Form.Label>Ажилтан</Form.Label>
                  <Select
                    defaultValue={{
                      value: "any",
                      label: "Бүгд",
                    }}
                    onChange={(e) => {
                      setEmployee(e.value);
                    }}
                    options={employees}
                  ></Select>
                </Form.Group>
                <Form.Group as={Col} sm="3" controlId="formGridPassword">
                  <Button
                    style={{ marginTop: "20px" }}
                    variant="primary"
                    className="btn btn-sm"
                    size="md"
                    onClick={handleSearch}
                  >
                    Хайх
                  </Button>
                </Form.Group>

                <Form.Group as={Col} sm="3" controlId="formGridPassword">
                  <Button
                    style={{ marginTop: "20px" }}
                    variant="primary"
                    className="btn btn-sm"
                    size="md"
                    onClick={handleDownload}
                  >
                    Татах
                  </Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Card.Header>
          <Card.Body className="table-full-width table-responsive px-0">
            <Table
              className="table-hover"
              style={{
                fontSize: "12px",
              }}
            >
              <thead>
                <tr>
                  <th className="border-0">Байгууллагын нэр</th>
                  <th className="border-0">Огноо</th>
                  <th className="border-0">Ажилтан</th>
                  <th className="border-0">Билл дугаар</th>
                  <th className="border-0">Receipt ID</th>
                  <th className="border-0">Олгосон оноо</th>
                  <th className="border-0">Хассан оноо</th>
                  <th className="border-0">Тайлбар</th>
                  <th className="border-0">Ticket number</th>
                  <th className="border-0">Амжилттай эсэх</th>
                </tr>
              </thead>
              <tbody style={{ wordBreak: "break-all" }}>
                {transactions.map((transaction, key) => (
                  <tr key={key}>
                    <td>{transaction.merchant_name}</td>
                    <td>{formatBeauty(transaction.created_at)}</td>
                    <td>{transaction.employee}</td>
                    <td>{transaction.bill_number}</td>
                    <td>{transaction.receipt_id}</td>
                    <td>{transaction.total_point}</td>
                    <td>{transaction.spend_amount}</td>
                    <td>{transaction.note || ""}</td>
                    <td>{transaction.ticket_number || ""}</td>
                    <td style={transaction.success ? styleGreen : styleRed}>
                      {transaction.success ? "амжилттай" : "амжилтгүй"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Loading loading={loading} />
            <Pagination pagination={pagination} onChange={getTransactions} />
          </Card.Body>
        </Card>
      </Col>
    </>
  );
}
export default EmployeeTransactions;
