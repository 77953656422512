import { Nav, Button, Form, Col, Row } from "react-bootstrap";
import { useState, useContext, useRef, useEffect } from "react";
import AppContext from "../../store/AppContext";
import Loading from "../Util/Loading";
import merchantLoyaltyService from "./../../services/merchantLoyalty.service";

function ConsumerSearch() {
  const appContext = useContext(AppContext);
  const showRoutes = [
    "/admin/user",
    "/admin/transaction",
    "/admin/consumer_log",
    "/admin/promo_coupon",
    "/admin/card_connection",
    "/admin/block",
    "/admin/seal",
    "/admin/spin",
    "/admin/badge",
    "/admin/loyalty",
    "/admin/autumn",
    "/admin/mission",
    "/admin/log",
    "/admin/grocery_stores",
    "/admin/eclinic",
    "/admin/lottery",
    "/admin/sloyalty",
  ];
  if (!showRoutes.includes(location.pathname)) {
    return null;
  }

  const [searchValue, setSearchValue] = useState("");
  const [MobileSearchValue, setMobileSearchValue] = useState("");
  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
  };
  const handleMobileSearchValue = (e) => {
    setMobileSearchValue(e.target.value);
  };
  let foundConsumer = null;
  if (appContext.consumer && appContext.consumer.mobile) {
    foundConsumer = (
      <>
        <b style={{ color: "green " }}>
          Олдсон хэрэглэгч {appContext.consumer.mobile}
        </b>
      </>
    );
  }

  return (
    <Nav.Item>
      <Row>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (
              searchValue.length === 10 &&
              searchValue.charCodeAt(0) >= 1040 &&
              searchValue.charCodeAt(0) <= 1103 &&
              searchValue.charCodeAt(1) >= 1040 &&
              searchValue.charCodeAt(1) <= 1103
            ) {
              if (location.pathname === "/admin/loyalty")
                appContext.searchLoyaltyUser("reg_num", searchValue.trim());
              else if (location.pathname === "/admin/MerchantLoyalty") {
                appContext.searchMerchantLoyaltyUser(
                  "reg_num",
                  searchValue.trim()
                );
              } else {
                appContext.searchUser(searchValue.trim());
              }
            } else {
              appContext.searchUser(searchValue.trim());
            }
            // appContext.notify('tr', 'success', 'Хэрэглэгч олдлоо')
          }}
          className="form-inline"
        >
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <Form.Control
            className="form-control mr-sm-2"
            value={searchValue}
            onChange={handleSearchValueChange}
            type="text"
            ref={appContext.inputRef}
            placeholder="хэрэглэгчийн мэдээлэл"
            required
          />
          <Button className="btn btn-primary " type="submit">
            <i className="fa fa-search"></i>&nbsp;Мэдээллээр хайх
          </Button>
        </Form>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            if (
              MobileSearchValue.length === 10 &&
              MobileSearchValue.charCodeAt(0) >= 1040 &&
              MobileSearchValue.charCodeAt(0) <= 1103 &&
              MobileSearchValue.charCodeAt(1) >= 1040 &&
              MobileSearchValue.charCodeAt(1) <= 1103
            ) {
              if (location.pathname === "/admin/loyalty")
                appContext.searchLoyaltyUser(
                  "reg_num",
                  MobileSearchValue.trim()
                );
              else if (location.pathname === "/admin/MerchantLoyalty") {
                appContext.searchMerchantLoyaltyUser(
                  "reg_num",
                  MobileSearchValue.trim()
                );
              } else {
                appContext.searchUserByMobile(MobileSearchValue.trim());
              }
            } else {
              appContext.searchUserByMobile(MobileSearchValue.trim());
            }
            // appContext.notify('tr', 'success', 'Хэрэглэгч олдлоо')
          }}
          className="form-inline"
        >
          &nbsp;&nbsp;
          <Form.Control
            className="form-control mr-sm-2"
            value={MobileSearchValue}
            onChange={handleMobileSearchValue}
            type="text"
            ref={appContext.inputRef}
            placeholder="Утасны дугаар"
            required
          />
          <Button className="btn btn-primary " type="submit">
            <i className="fa fa-search"></i>&nbsp;Дугаараар хайх
          </Button>
          <div style={{ marginLeft: "10px" }}>
            <Loading loading={appContext.consumerLoading} />
          </div>
          {foundConsumer}
        </Form>
      </Row>
    </Nav.Item>
  );
}

export default ConsumerSearch;
