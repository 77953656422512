import { Button, Modal, Table, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import CollectiveService from "../../services/collective.service";
import Loading from "../Util/Loading";
import consumerService from "services/consumer.service";

function HamtdaaCheckModal({ familyId, refreshCollective }) {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [hamtdaaStatus, setHamtdaaStatus] = useState(true);
  const [takenActions, setTakenActions] = useState([]);
  const [checkResult, setCheckResult] = useState();
  const handleClose = () => {
    setShow(false);
    setHamtdaaStatus(true);
    setCheckResult(null);
    setTakenActions([]);
  };

  const handleShow = async (e) => {
    try {
      e.preventDefault();
      setShow(true);
      await checkHamtdaa();
    } catch (err) {
      setShow(false);
    }
  };

  const checkHamtdaa = async () => {
    try {
      setLoading(true);
      let result = await CollectiveService.checkHamtdaa(familyId);
      setCheckResult(result.data);
      await checkUntStatus(result.data.unt_members);
      if (result && result.data && result.data.code === 0) {
        setHamtdaaStatus(true);
      } else {
        setHamtdaaStatus(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const checkUntStatus = async (members) => {
    try {
      const list = await Promise.all(
        members.map(async (member) => {
          const response = await consumerService.checkActive(member.trim());
          return {
            mobile: member,
            status: response.data,
          };
        })
      );
      setCheckResult((prevState) => ({
        ...prevState,
        unt_members_status: [...list],
      }));
    } catch (err) {
      console.log("uni status check error", err);
    }
  };

  const syncHamtdaa = async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      let result = await CollectiveService.syncHamtdaa(familyId);
      if (result && result.data && result.data.actions.length > 0) {
        setTakenActions(result.data.actions);
      }
      refreshCollective();
      setLoading(false);
      await checkHamtdaa();
    } catch (err) {
      // setShow(false)
      setLoading(false);
    }
  };

  return (
    <>
      <Button size="sm" type="button" onClick={handleShow}>
        <i className="fa fa-sync"></i>
        Шалгах
      </Button>

      <Modal
        style={{ fontSize: "13px" }}
        show={show}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Хамтдаа шалгах</Modal.Title>
          <Loading loading={loading} />
        </Modal.Header>
        <Modal.Body>
          {hamtdaaStatus ? (
            <h6 style={{ color: "green" }}>In sync</h6>
          ) : (
            <h6 style={{ color: "red" }}>Out of sync</h6>
          )}
          <Row>
            {checkResult && checkResult.actions.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <th className="border-0">Утасны дугаар</th>
                    <th className="border-0">Unitel дээр хийгдэх үйлдэл</th>
                  </tr>
                </thead>
                <tbody>
                  {checkResult &&
                    checkResult.actions.map((action) => (
                      <tr key={action.mobile}>
                        <td>{action.mobile || ""}</td>
                        <td>{action.action_name || ""}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : null}

            {takenActions.length > 0 ? (
              <Table>
                <thead>
                  <tr>
                    <th className="border-0">Утасны дугаар</th>
                    <th className="border-0">Хийгдсэн үйлдэл</th>
                    <th className="border-0">Хариу</th>
                  </tr>
                </thead>
                <tbody>
                  {takenActions.map((action) => (
                    <tr key={action.mobile}>
                      <td>{action.mobile || ""}</td>
                      <td>{action.action_name || ""}</td>
                      <td>{action.result || ""}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : null}
          </Row>
          <Row>
            <Col md="3">
              <Table>
                <thead>
                  <tr>
                    <th className="border-0">UA гишүүд</th>
                  </tr>
                </thead>
                <tbody>
                  {checkResult &&
                    checkResult.ua_members.map((member) => (
                      <tr key={member}>
                        <td>{member || ""}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
              {checkResult &&
              checkResult.ua_extra &&
              checkResult.ua_extra.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th className="border-0">UA д байгаа илүү гишүүд</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkResult &&
                      checkResult.ua_extra.map((member) => (
                        <tr key={member}>
                          <td>{member || ""}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : null}
            </Col>
            <Col md="9">
              <Table>
                <thead>
                  <tr>
                    <th className="border-0">Unitel гишүүд</th>
                    <th className="border-0">Unitel Төлөв</th>
                  </tr>
                </thead>
                <tbody>
                  {checkResult && checkResult?.unt_members_status
                    ? checkResult?.unt_members_status?.map((member) => (
                        <tr key={member?.mobile}>
                          <td>{member?.mobile || ""}</td>
                          <td>{member?.status?.message || ""}</td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </Table>
              {checkResult &&
              checkResult.unt_extra &&
              checkResult.unt_extra.length > 0 ? (
                <Table>
                  <thead>
                    <tr>
                      <th className="border-0">Unitel д байгаа илүү гишүүд</th>
                    </tr>
                  </thead>
                  <tbody>
                    {checkResult &&
                      checkResult.unt_extra.map((member) => (
                        <tr key={member}>
                          <td>{member || ""}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : null}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button size="sm" onClick={handleClose}>
            Хаах
          </Button>
          {hamtdaaStatus ? null : (
            <Button size="sm" type="button" onClick={syncHamtdaa}>
              <i className="fa fa-sync"></i>
              Засах
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default HamtdaaCheckModal;
