import { useState, useEffect } from "react";
import {
  Container,
  Card,
  Table,
  Row,
  Col,
  Button,
  Form,
} from "react-bootstrap";
import BlockSealService from "../services/blockSeal.service";
import Loading from "../components/Util/Loading";
import { formatPretty } from "../helpers/dateHelper";
import ConsumerInfo from "../components/Consumer/ConsumerInfo";
import GenericModalNote from "../components/Modal/GenericModalNote";

function Block(props) {
  if (!props.consumer || !props.consumer.profile) {
    return <h4>Хэрэглэгчийн мэдээлэл байхгүй байна. Хэрэглэгч хайна уу!</h4>;
  }

  const [loading, setLoading] = useState(false);
  const [blockList, setBlockList] = useState([]);
  const styleGreen = { color: "green" };
  const styleRed = { color: "red" };

  const getBlocks = async () => {
    try {
      setLoading(true);
      setBlockList([]);
      let blocks = await BlockSealService.getBlockedList(props.consumer._id);
      setBlockList(blocks);
      if (blocks.length === 0) {
        props.notify("tr", "danger", `Блок мэдээлэл олдсонгүй.`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const unblock = async ({ blockId, consumerId, note }) => {
    try {
      let response = await BlockSealService.unblock(blockId, consumerId, note);

      props.notify(
        "tr",
        "success",
        `${props.consumer.mobile} дугаартай хэрэглэгчийн блокыг гаргалаа.`
      );
      await getBlocks();
    } catch (err) {
      props.notify("tr", "danger", err.message);
    }
  };
  useEffect(() => {
    setBlockList([]);
    setLoading(false);
  }, [props.consumer]);

  return (
    <>
      <Container fluid>
        <Row>
          <Col md="12">
            <ConsumerInfo consumer={props.consumer} />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <Card className="striped-tabled-with-hover">
              <Card.Header>
                <Card.Title as="h4">Блок лист</Card.Title>
                <Form>
                  <Form.Row className="align-items-center">
                    <Form.Group as={Col} sm="3" controlId="formGridPassword">
                      <Button
                        variant="primary"
                        className="btn btn-sm"
                        size="md"
                        onClick={getBlocks}
                      >
                        ХАЙХ
                      </Button>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <Table
                  className="table-hover"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <thead>
                    <tr>
                      <th className="border-0">Партнер</th>
                      <th className="border-0">Салбарын</th>
                      <th className="border-0">Терминал</th>
                      <th className="border-0">Төлөв</th>
                      <th className="border-0">Гаргасан хэрэглэгч</th>
                      <th className="border-0">Блоклосон огноо</th>
                      <th className="border-0">Блокоос гарах огноо</th>
                      <th className="border-0">Гаргасан огноо</th>
                      <th className="border-0">Тайлбар</th>
                      <th className="border-0">Удаа</th>
                      <th className="border-0">Блок тайлах</th>
                    </tr>
                  </thead>
                  {blockList.length === 0 ? (
                    <tbody>
                      <tr>
                        <td align="center" colSpan="11">
                          <h6>Блокын мэдээлэл байхгүй байна.</h6>
                        </td>
                      </tr>
                    </tbody>
                  ) : (
                    <tbody style={{ wordBreak: "break-all" }}>
                      {blockList.map((block, key) => (
                        <tr key={key}>
                          <td>{block.merchant_name || ""}</td>
                          <td>{block.branch_name}</td>
                          <td>{block.terminal}</td>
                          <td
                            style={block.status === 1 ? styleRed : styleGreen}
                          >
                            {block.block_status}
                          </td>
                          <td>{block.employee || ""}</td>
                          <td>
                            {block.created_at
                              ? formatPretty(block.created_at)
                              : ""}
                          </td>
                          <td>{formatPretty(block.e_datetime)}</td>
                          <td>
                            {block.unblock_date
                              ? formatPretty(block.unblock_date)
                              : ""}
                          </td>
                          <td>{block.description}</td>
                          <td>{block.blocked_count}</td>
                          {block.status === 1 ? (
                            <td>
                              <GenericModalNote
                                onClick={unblock}
                                params={{
                                  blockId: block._id,
                                  consumerId: props.consumer._id,
                                }}
                                action={"Блок гаргах"}
                                title={"Блок гаргах"}
                                body={`Та ${props.consumer.mobile} дугаартай хэрэглэгчийн ${block.merchant_name} дээрхи блокыг гаргахдаа итгэлтэй байна уу?`}
                              />
                            </td>
                          ) : (
                            <td></td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  )}
                </Table>
                <Loading loading={loading} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}
export default Block;
