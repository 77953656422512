import { useEffect, useState } from "react";
import request from "services/request.service";
import { Row, Col, Card, Form, Table, Tab, Button } from "react-bootstrap";
import Loader from "react-spinners/PulseLoader";

export default function Univision(props) {
  const { mobile, consumer_id, notify } = props;
  const [subs, setsubs] = useState({});
  const [loader1, setLoader1] = useState(false);
  const [loader2, setLoader2] = useState(false);

  useEffect(async () => {
    try {
      setLoader1(true);

      const result = await request(
        `/univision/crm/subids?consumer=${consumer_id}&mobile=${mobile}`,
        "GET"
      );
      setsubs(result.data.data);
    } catch (err) {
    } finally {
      setLoader1(false);
    }
  }, [mobile]);
  const activate = async () => {
    try {
      setLoader2(true);
      setTimeout(async () => {
        const result = await request(
          `/univision//crm/activate/${props.consumer_id}`,
          "POST",
          {
            mobile: mobile,
            subids: [...subs.subids],
          }
        );
        notify("tr", "success", result.data.data.msg);
      }, 3000);
    } catch (err) {
      console.log(err);
    } finally {
      setLoader2(false);
    }
  };
  return (
    <>
      <Row>
        <Col md={"4"}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center mb-3">
              <Card.Title as="h4" className="mb-0">
                Идэвхжүүлэх боломжтой Юнивишнууд
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Univision sub</th>
                  </tr>
                </thead>
                <tbody>
                  {subs?.subids?.map((e, ind) => (
                    <tr>
                      <td>{ind + 1}</td>
                      <td>{e}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div style={{ display: "flex", "justify-content": "flex-end" }}>
                {subs?.subids?.length ? (
                  <Button
                    onClick={() => {
                      activate();
                    }}
                  >
                    Идэвхжүүлэх
                  </Button>
                ) : null}
              </div>
              {loader2 ? <Loader /> : null}
            </Card.Body>
          </Card>
        </Col>
        <Col md={"4"}>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center mb-3">
              <Card.Title as="h4" className="mb-0">
                Оноо цуглуулдаг Юнивишнууд
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table>
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Univision sub</th>
                  </tr>
                </thead>
                <tbody>
                  {subs?.available?.map((e, ind) => (
                    <tr>
                      <td>{ind + 1}</td>
                      <td>{e}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {loader1 ? <Loader /> : null}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
