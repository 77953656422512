import { useEffect, useState } from "react";
import { Table, Row, Col, Container, Card } from "react-bootstrap";
import sloyaltyService from "services/sloyalty.service";

export default function SLoyalty(props) {
  const [generalInfo, setGeneralinfo] = useState();
  const [activates, setActivate] = useState();
  const [transactions, setTransaction] = useState();
  const [products, setProducts] = useState([]);
  const [coupons, setCoupons] = useState([]);

  useEffect(async () => {
    const res = await sloyaltyService.generalInfo();
    setGeneralinfo(res.data);
    const resp = await sloyaltyService.getProducts();
    setProducts(resp.data);
  }, []);
  useEffect(async () => {
    if (props?.consumer?._id) {
      const coupon = await sloyaltyService.getCoupons(props.consumer._id);
      setCoupons(coupon.data);
      const act = await sloyaltyService.getConsumer(props.consumer.mobile);
      setActivate(act.data);
      const trans = await sloyaltyService.getTransaction(props.consumer.mobile);
      setTransaction(trans.data);
    }
  }, [props.consumer]);

  return (
    <div>
      <Container fluid>
        <Col md="12">
          <Card className="stripied-tabled-with-hover">
            <Card.Header>
              <Card.Title>Урамшуулал</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col md="8">
                  <Table
                    className="table-hover"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th style={{ width: "240px" }}>Төлбөртэй лояалти</th>
                        <th style={{ width: "240px" }}>Үндсэн урамшуулал</th>
                        <th>Байгууллагууд</th>
                      </tr>
                    </thead>
                    <tbody>
                      {generalInfo?.tiers?.map((tier, ind) => (
                        <tr key={ind}>
                          <td>
                            {"Төрөл " +
                              tier?.general?.tier +
                              ". " +
                              tier?.general?.ui_loyal_percent +
                              " --- " +
                              tier.general.ui_duration_date}
                          </td>
                          <td key={"percent"}>
                            {tier?.general?.categories?.map((cat) => (
                              <div>
                                {cat.name + " --- " + cat.loyal_percent + " %"}
                              </div>
                            ))}
                          </td>
                          <td key={"partner"}>
                            {tier?.partner.map((part) => (
                              <>
                                {part.name +
                                  "- " +
                                  part.loyal_percent +
                                  " % , "}
                              </>
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col md="4">
                  <Table
                    className="table-hover"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Нэмэлт Урамшуулал</th>
                      </tr>
                    </thead>
                    <tbody>
                      {generalInfo?.promotion?.map((promo, ind) => (
                        <tr key={ind}>
                          <td style={{}}>
                            {ind +
                              1 +
                              ". " +
                              promo?.label +
                              " - " +
                              promo?.description}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                Төлбөртэй лояалти идэвхжүүлсэн мэдээлэл
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th>Төлбөртэй лояалти</th>
                    <th>Идэвхжүүлсэн огноо</th>
                    <th>Гүйлгээний ID</th>
                    <th>Дуусах огноо</th>
                    <th>Суваг</th>
                  </tr>
                </thead>
                <tbody>
                  {activates?.map((act) => (
                    <tr>
                      <td>{"Төрөл " + act.tier}</td>
                      <td>{act.created_at?.slice(0, 16)}</td>
                      <td>{act.order_id}</td>
                      <td>{act.end_at?.slice(0, 16)}</td>
                      <td>{act.grant_chanel}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                Төлбөртэй лояалти үйлчилгээгээр цуглуулсан нэмэлт оноо
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th>Төлбөртэй лояалти</th>
                    <th>Огноо</th>
                    <th>Мерчант</th>
                    <th>Нэмэлт онооны дүн</th>
                    <th>Үндсэн гүйлгээний ID</th>
                  </tr>
                </thead>
                <tbody>
                  {transactions?.map((trans) => (
                    <tr>
                      <td></td>
                      <td>{trans.receipt.created_at}</td>
                      <td></td>
                      <td>{trans.receipt.bonus_point}</td>
                      <td>{trans.receipt._id}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Боломжит Купон</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th>Купон нэр</th>
                    <th>Үндсэн үнэ</th>
                    <th>Хөнгөлөлтийн хувь</th>
                    <th>Төлөх дүн</th>
                    <th>Үлдэгдэл</th>
                  </tr>
                </thead>
                <tbody>
                  {products?.map((cp) => (
                    <tr>
                      <td>{cp.name}</td>
                      <td>{cp.presale_price}</td>
                      <td>{cp.discount_text}</td>
                      <td>{cp.cash_price}</td>
                      <td>{cp.qty}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>

        <Col md="8">
          <Card>
            <Card.Header>
              <Card.Title as="h4">Хэрэглэгчийн идэвхжүүлсэн купон</Card.Title>
            </Card.Header>
            <Card.Body>
              <Table
                className="table-hover"
                style={{
                  fontSize: "12px",
                }}
              >
                <thead>
                  <tr>
                    <th>Идэвхжүүлсэн купон</th>
                    <th>Идэвхжүүлсэн огноо</th>
                    <th>Хүчинтэй хугацаа</th>
                    <th>Ашигласан эсэх</th>
                    <th>Ашигласан огноо</th>
                    <th>Ашигласан ...</th>
                  </tr>
                </thead>
                <tbody>
                  {coupons?.map((cp) => (
                    <tr>
                      <td>{cp.name}</td>
                      <td>{cp.created_at}</td>
                      <td>{cp.expire_date}</td>
                      <td>{cp.is_redeemed}</td>
                      <td>{cp.redeemed_date}</td>
                      <td>{cp.redeemed_note}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Container>
    </div>
  );
}
